<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Fixtures from "@/services/Fixtures";
import Http from "@/services/Http";
import Swal from "sweetalert2";
import modalAddMatch from "../../../components/modals/simulator_new/modalAddMatch.vue";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, modalAddMatch },
  page: {
    title: "Matches",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "New Matches",
      items: [
        {
          text: "Matches",
          href: "/",
        },
      ],
      isBusy: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "date_time",
      sortDesc: true,
      fields: [
        {
          key: "match_id",
          label: "Match Id",
        },
        {
          key: "competition_name",
          label: "Competition"
        },
        {
          key: "home_team_code",
          label: "Home",
        },
        {
          key: "away_team_code",
          label: "Away",
        },
        {
          key: `score`,
          label: "Score",
        },
        {
          key: "date_time",
          label: "Date",
          sortable: true,
        },
        {
          key: "venue_name",
          label: "Venue",
        },
        {
          key: "match_status",
          label: "Status",
        },
        "action",
      ],
      tryingToSubmit: false,
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  async created() {
    this.getMatches();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getMatches() {
      try {
        this.toggleBusy();
        const response = await Http.get("/football/matches");
        console.log(response);
        this.tableData = response.data.matches;
        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = [];
      }
    },
    deleteSportMatch(id) {
      Swal.fire({
        title: "Remove match?",
        text: "This match will be removed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
      }).then((result) => {
        if (result.value) {
          try {
            this.tryingToSubmit = true;
            Fixtures.deleteSportMatch(id).then((response) => {
              const res = response.data ? response.data : false;
              const error = response.data.error
                ? response.data.error
                : "Failed";
              if (res) {
                this.getMatches();
                Swal.fire(
                  "Removed match",
                  "Match is successfully removed!",
                  "success"
                );
              } else {
                Swal.fire("Fail!", error, "warning");
              }
            });
            this.tryingToSubmit = false;
          } catch (error) {
            console.log("HERE");
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
              this.tryingToSubmit = false;
          }
        }
      });
    },
    modalAddMatch() {
      this.$bvModal.show("add_match");
    },
    refreshTable() {},
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Match added!`,
        showConfirmButton: false,
        timer: 2000,
      });
      this.getMatches();
    },
    async success() {
      this.getMatches();
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="mb-3">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-success waves-effect waves-light"
                    @click="modalAddMatch"
                  >
                    <i class="mdi mdi-plus me-2"></i>
                    Create New
                  </a>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-table
              :busy="isBusy"
              table-class="table table-centered datatable table-card-list"
              thead-tr-class="bg-transparent"
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              empty-text="No Data Found"
              @filtered="onFiltered"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(score)="data">
                {{ data.item.score_home || 0 }} -
                {{ data.item.score_away || 0 }}
              </template>
              <template v-slot:cell(match_status)="data">
              <div
                class="badge bg-pill bg-soft-success font-size-12"
                :class="{
                  'bg-soft-danger': data.item.match_status === 'Cancelled',
                  'bg-soft-warning': data.item.match_status === 'Postponed',
                  'bg-orange': data.item.match_status === 'Suspended',
                  'bg-gray': data.item.match_status === 'Fixture',
                  'bg-primary': data.item.match_status === 'Played',

                }"
              >
                {{ data.item.match_status.toUpperCase() }}
              </div>
            </template>
              <template #cell(action)="data" class="d-flex align-items-center">
                <router-link
                  :to="'matches_new/' + data.item.match_id"
                  class="px-2 text-black"
                  v-b-tooltip.hover
                  title="Match details"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="sm"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                    />
                  </svg>
                </router-link>
                <a
                  href="javascript:void(0);"
                  class="px-2 text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  @click="deleteSportMatch(data.item.match_id)"
                >
                  <i class="uil uil-trash-alt font-size-18"></i>
                </a>
              </template>
            </b-table>

            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalAddMatch @success="success" />
  </Layout>
</template>

<style>
.sm {
  width: 1rem;
  height: 1rem;
}
.bg-orange{
  background-color: #FFB07A!important;
  color: #f27c2c;
}
.bg-gray{
  background-color: #D3D3D3!important;
  color: #6e6d6d;
}
.bg-gold{
  background-color: #FFD700;
  /* color: #FFFFFF; */
}
.bg-primary{
  background-color: #a7b0f0!important;
  color: #5871ed;
}
</style>
